import {productQueryParamNames} from '@mfb/cookbook';

export interface ProductPreselection {
  people?: number;
  dinners?: number;
}

const getSelectionFromQueryParams = (): ProductPreselection => {
  const queryParams = new URLSearchParams(window.location.search);
  const people = Number(queryParams.get(productQueryParamNames.people));
  const dinners = Number(queryParams.get(productQueryParamNames.dinners));

  return {people, dinners};
};

export default getSelectionFromQueryParams;
