import {useEffect, useState} from 'react';

export interface UseCountdownProps {
  isEnabled?: boolean;
  deadline?: Date;
}

export function useCountdown({isEnabled = true, deadline}: UseCountdownProps) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const isNotZero = Boolean(days || hours || minutes || seconds);

  const leading0 = (num: number) => {
    return num < 10 ? '0' + num : num;
  };

  const getTimeUntil = (endDate: Date) => {
    const time =
      Date.parse(endDate.toISOString()) - Date.parse(new Date().toISOString());
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };

  useEffect(() => {
    if (isEnabled && deadline) {
      // console.log('::: starting :::', deadline);

      const intervalId = setInterval(() => getTimeUntil(deadline), 1000);
      return () => {
        // console.log('::: shutting down :::');
        clearInterval(intervalId);
      };
    }
  }, [isEnabled]);

  return {
    days,
    hours,
    minutes,
    seconds,
    isEnabled,
    isNotZero,
    deadline,
    leading0,
  };
}
