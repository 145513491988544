import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';

import {SiteQueryModel} from './SiteMetadataQueryModel';

export const WithSiteMetadata = <P extends {}>(
  Component: React.ComponentType<P & SiteQueryModel>
): React.ComponentClass<P> => {
  class SiteMetadataHoc extends React.Component<P> {
    public render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              site {
                ...SiteMetadataFragment
              }
            }
          `}
          render={(data: {site: SiteQueryModel}) => (
            <Component {...(this.props as P)} {...data.site} />
          )}
        />
      );
    }
  }

  return SiteMetadataHoc;
};
