import {ActionButtonProps, Brand, CoreTypography, SmartLink} from '@mfb/lego';
import classnames from 'classnames';
import * as React from 'react';
import {
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';

import {PrimaryLogo, SecondaryLogo} from '../logo/Logo';
import GlobalMenuWrapper from './GlobalMenuWrapper';
import {Login} from './Login';
import MenuTree from './MenuTree';
import styled from 'styled-components';

interface State {
  isOpen: boolean;
}

interface BaseMenuItem {
  id: string;
  title: string;
  link?: string;
}

interface MenuItem extends BaseMenuItem {
  items?: Array<MenuItem>;
}

interface Props {
  menuItem?: Array<MenuItem>;
  brand: Brand;
  loginUrl: string;
  getStartedButton?: ActionButtonProps;
}

const StyledNavItemsContainer = styled.div`
  @media ${(c) => c.theme.layout.deviceMediaQueries.max.md} {
    flex-direction: column;
    gap: 0px;

    margin-left: 0px;
  }
  display: flex;
  gap: 32px;

  margin-left: 8px; // To stop the left nav item to touch the logo
`

const StyledSmartLink = styled(SmartLink)<{
  brandIsFreshStart?: boolean;
}>`
 &&{
  font-weight: ${CoreTypography.fontWeight[600]};
  padding: ${c=>c.brandIsFreshStart? '0px': ''};
 }
`

const StyledDropdownToggle = styled(DropdownToggle)`
&&{
  font-weight: ${CoreTypography.fontWeight[600]};
 }
`
export default class Navigation extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  public toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  public render() {
    const brandIsBargainBox = this.props.brand === Brand.BB;
    const brandIsFreshStart = this.props.brand === Brand.FS;
    const brandIsMFB = this.props.brand === Brand.MFB;

    return (
      <React.Fragment>
        <div className="w-100 bg-secondary d-none d-md-block">
          <div className="container pt-1">
            <GlobalMenuWrapper activeItemClassName="bg-white" />
          </div>
        </div>
        <Navbar
          light={true}
          expand="md"
          className={classnames(
            'py-3',
            brandIsMFB && 'mfb-dark-color-override navbar-dark',
            brandIsBargainBox && 'bb-primary-color-override navbar-dark',
            brandIsFreshStart && 'fs-dark-color-override navbar-dark'
          )}
        >
          <div className="container">
            <NavbarToggler
              title="toggle navigation on mobile"
              value="toggle-mobile-navigation"
              onClick={() => this.toggle()}
              className="border-0"
            />
            <StyledSmartLink href="/">
              {brandIsBargainBox || brandIsFreshStart ? (
                <SecondaryLogo />
              ) : (
                <PrimaryLogo />
              )}
            </StyledSmartLink>
            <Collapse isOpen={this.state.isOpen} navbar={true} className="mt-2">
              <Nav
                className="w-100 d-flex justify-content-center"
                navbar={true}
              >
                <Login
                  loginUrl={this.props.loginUrl}
                  getStartedButton={this.props.getStartedButton}
                  brandIsBargainBox={brandIsBargainBox}
                />
                <StyledNavItemsContainer>
                  {this.props.menuItem &&
                    this.props.menuItem.map(m =>
                      m.items ? (
                        <UncontrolledDropdown
                          nav={true}
                          inNavbar={true}
                          key={m.id}
                        >
                          <React.Fragment>
                          <StyledDropdownToggle
                              className={classnames('text-white nav-link--override p-0', 
                                    'd-none d-md-block')}
                              nav={true}
                              caret={true}
                            >
                              {m.title}
                            </StyledDropdownToggle>
                            <DropdownToggle
                              className="text-white mt-2 w-100 d-md-none"
                              color="primary"
                              nav={false}
                              caret={false}
                            >
                              {m.title}
                            </DropdownToggle>
                          </React.Fragment>
                          <DropdownMenu>
                            <MenuTree menu={m} />
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      ) : (
                        <React.Fragment key={m.id}>
                          <StyledSmartLink 
                            brandIsFreshStart={brandIsFreshStart}
                            className={classnames('text-white nav-link--override',
                              'd-none d-md-block')}
                            href={m.link}
                          >
                            {m.title}
                          </StyledSmartLink>
                          <SmartLink
                            className="text-white mt-2 w-100 d-md-none btn btn-primary"
                            href={m.link}
                          >
                            {m.title}
                          </SmartLink>
                        </React.Fragment>
                      )
                    )}
                  </StyledNavItemsContainer>
                <div className="mt-2 py-1 w-100 bg-secondary d-md-none">
                  <GlobalMenuWrapper
                    className="flex-column"
                    activeItemClassName=""
                  />
                </div>
              </Nav>
            </Collapse>
            <Login
              lg={true}
              loginUrl={this.props.loginUrl}
              getStartedButton={this.props.getStartedButton}
              brandIsBargainBox={brandIsBargainBox}
            />
          </div>
        </Navbar>
      </React.Fragment>
    );
  }
}
