import {RecipeDetailModel, RecipeDetailModelLegacy} from '@mfb/cookbook';

import {RecipeClient} from './client';

export const loadRecipeDetailLegacy = async (
  recipeNumber?: string,
  recipeVersion?: string,
  partition?: string,
  recipeId?: number
) => {
  const client = new RecipeClient(process.env.GATEWAY_URL);

  return (await client.getRecipeByRVP(
    recipeNumber || '',
    recipeVersion || '',
    partition || '',
    recipeId
  )) as RecipeDetailModelLegacy;
};

export const loadRecipeDetail = async (
  recipeNumber: string,
  recipeVersion: string,
  partition: string,
  deliveryWeek: Date,
) => {
  const client = new RecipeClient(process.env.GATEWAY_URL);

  return (await client.getRecipeDetail(
    recipeNumber, recipeVersion, partition, deliveryWeek
  )) as RecipeDetailModel;
}
