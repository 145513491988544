import * as React from 'react';
import {NavLink} from 'reactstrap';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import {MenuItemContentModel} from '../../contentful/menu/MenuContentModel';
import MenuTree from './MenuTree';

interface Props {
  parent: MenuItemContentModel;
  child: MenuItemContentModel;
  iterator: number;
}

const MenuBranch: React.FunctionComponent<Props> = ({
  parent,
  child,
  iterator,
}) =>
  child && (
    <React.Fragment>
      {child.items && child.title && (
        <NavLink className="ml-3 text-dark text-uppercase mt-2">
          <b>{child.title}</b>
        </NavLink>
      )}
      {child.items && <MenuTree menu={child} />}
      {child.items && parent.items && iterator !== parent.items.length - 1 && (
        <DropdownItem divider className="m-0 d-none d-md-block" />
      )}
    </React.Fragment>
  );

export default MenuBranch;
