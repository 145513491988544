import {CookbookStrategyContext, StockProvider} from '@mfb/cookbook';
import {NavigationProvider, NavigationType} from '@mfb/lego';
import {createHistory} from '@reach/router';
import {navigate} from 'gatsby';
import * as React from 'react';

import {ExternalLinkWrapper} from '../components/ExternalLinkWrapper';
import {InternalLinkWrapper} from '../components/InternalLinkWrapper';
import {MetadataContentModel} from '../contentful/metadata/MetadataContentModel';
import PageSettingsContentModel from '../contentful/pageSettings/PageSettingsContentModel';
import {ExternalPaths, WithPathRules} from '../contentful/PathRulesHoc';
import {WithSiteMetadata} from '../contentful/SiteMetadataHoc';
import {SiteQueryModel} from '../contentful/SiteMetadataQueryModel';
import {WebsiteCookbook} from '../contentful/WebsiteCookbook';
import {loadBufferStockAsync, loadStockAsync} from '../util/loadStockAsync';
import getSelectionFromQueryParams, {
  ProductPreselection,
} from './getSelectionFromQueryParams';
import Layout from './Layout';

export interface PageModel {
  metadata: MetadataContentModel;
  pageSettings?: PageSettingsContentModel;
}

interface RenderProp {
  render(pageSettings?: PageSettingsContentModel): React.ReactNode;
}

interface State {
  productPreselection: ProductPreselection;
  addressId?: number;
}

type Props = RenderProp & PageModel & SiteQueryModel & ExternalPaths;

class PurePageTemplate extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      addressId: undefined,
      productPreselection: {
        people: undefined,
        dinners: undefined,
      },
    };

    if (typeof window !== `undefined`) {
      const selection = getSelectionFromQueryParams();

      const queryString = window.location.search.substring(1);
      const params = new URLSearchParams(queryString);

      const address = parseInt(params.get('AddressId') || '0') || undefined;

      this.state = {
        addressId: address,
        productPreselection: selection,
      };
    }
  }

  public componentDidMount() {
    const history = createHistory(window as any);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const ReactAI = require('react-appinsights');
    ReactAI.init(
      {instrumentationKey: this.props.siteMetadata.applicationInsightsKey},
      history as any
    );
    ReactAI.setAppContext({urlReferrer: window.document.referrer});
  }

  public render() {
    const {
      metadata,
      pageSettings,
      externalPaths,
      siteMetadata,
      render,
    } = this.props;
    const {people, dinners} = this.state.productPreselection;
    const updatedPageSettings: PageSettingsContentModel | undefined =
      people || dinners || pageSettings
        ? {
            preselectedPeople:
              people || (pageSettings && pageSettings.preselectedPeople),
            preselectedDinners:
              dinners || (pageSettings && pageSettings.preselectedDinners),
            ...pageSettings,
          }
        : undefined;

    return (
      <CookbookStrategyContext.Provider value={new WebsiteCookbook()}>
        <StockProvider
          loadOneOffStockAsync={async (skus: Array<string>)=>
            loadStockAsync(skus, siteMetadata.gatewayUrl)
          }
          loadBufferStockAsync={async () =>
            loadBufferStockAsync(siteMetadata.gatewayUrl, this.state.addressId)
          }
        >
          <NavigationProvider
            rulePaths={externalPaths}
            ruleType={NavigationType.External}
            internalNavigation={{
              component: InternalLinkWrapper,
              callback: (href, navigateOptions) => {
                navigate(href, navigateOptions);
              },
            }}
            externalNavigation={{
              component: ExternalLinkWrapper,
              callback: href => {
                window.location.href = href;
              },
            }}
          >
            <Layout
              metadata={{
                ...metadata,
                allowIndexing:
                  siteMetadata.environment === 'production' &&
                  metadata.allowIndexing,
              }}
              hideFooter={updatedPageSettings && updatedPageSettings.hideFooter}
              hideDiscountBanner={
                updatedPageSettings && updatedPageSettings.hideDiscountBanner
              }
              externalPaths={externalPaths}
              siteMetadata={siteMetadata}
              hideHeader={updatedPageSettings && updatedPageSettings.hideHeader}
            >
              {render(updatedPageSettings)}
            </Layout>
          </NavigationProvider>
        </StockProvider>
      </CookbookStrategyContext.Provider>
    );
  }
}

const PageTemplate = WithPathRules(WithSiteMetadata(PurePageTemplate));

export default PageTemplate;
