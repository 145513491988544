import {isNotNullOrUndefined} from '@mfb/lego';

import {ProductClient, ProductOptionStockAvailability} from './client';

const mapStockResponse = (response: Array<ProductOptionStockAvailability>) =>
  response
    .map(
      r =>
        (r.sku && {
          sku: r.sku,
          hasStock: r.hasStockAvailable,
        }) ||
        undefined
    )
    .filter(isNotNullOrUndefined);

export const loadStockAsync = async (
  skus: Array<string>,
  gatewayUrl: string
) => {
  const response =
    (await new ProductClient(gatewayUrl).managedStockIsAvailableForSelectedSkus(
      {
        skus,
      }
    )) || [];

  return mapStockResponse(response);
};

export const loadBufferStockAsync = async (
  gatewayUrl: string,
  addressId?: number
) => {
  const response =
    (await new ProductClient(gatewayUrl).getBufferStockForWeekAndAddress(
      undefined,
      addressId
    )) || [];

  return mapStockResponse(response);
};
