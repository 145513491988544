import {ActionButtonProps} from '@mfb/lego';
import classnames from 'classnames';
import * as React from 'react';

import {mfbScrollSmooth} from '../MFBScroll';

interface Props {
  lg?: boolean;
  loginUrl: string;
  getStartedButton?: ActionButtonProps;
  brandIsBargainBox?: boolean;
}

const scrollToSection = (target: string | undefined) => {
  if (target) {
    mfbScrollSmooth(target);
  }
};
const Lg: React.FunctionComponent<Props> = ({
  loginUrl,
  getStartedButton,
  brandIsBargainBox,
}) => (
  <div className="d-none d-md-block text-right" style={{minWidth: '220px'}}>
    <a
      href={loginUrl}
      className={classnames('btn btn-sm btn-link align-middle text-white')}
      data-category="user-interaction"
      data-conversion="micro"
      data-section="marketing"
      data-action="login-click"
      data-details="large-viewport"
    >
      Login
    </a>
    {getStartedButton && (
      <a
        href={getStartedButton.url}
        className={classnames([
          'ml-3',
          'btn',
          'btn-primary text-white',
          {'rounded-pill': brandIsBargainBox},
        ])}
        data-category="user-interaction"
        data-conversion="micro"
        data-section="marketing"
        data-action="signup-click"
        data-details="large-viewport"
        onClick={() => scrollToSection(getStartedButton.scrollTarget)}
      >
        {getStartedButton.label}
      </a>
    )}
  </div>
);

const Sm: React.FunctionComponent<Props> = ({
  loginUrl,
  getStartedButton,
  brandIsBargainBox,
}) => (
  <div className="d-md-none">
    <a
      href={loginUrl}
      className={classnames([
        'btn btn-outline-primary btn-block',
        {'rounded-pill': brandIsBargainBox},
      ])}
      data-category="user-interaction"
      data-conversion="micro"
      data-section="marketing"
      data-action="login-click"
      data-details="default-viewport"
    >
      Login
    </a>
    {getStartedButton && (
      <a
        href={getStartedButton.url}
        className={classnames([
          'btn',
          'btn-outline-primary',
          'btn-block',
          {'rounded-pill': brandIsBargainBox},
        ])}
        data-conversion="micro"
        data-section="marketing"
        data-action="signup-click"
        data-details="default-viewport"
        onClick={() => scrollToSection(getStartedButton.scrollTarget)}
      >
        {getStartedButton.label}
      </a>
    )}
  </div>
);

export const Login: React.FunctionComponent<Props> = ({lg, ...props}) =>
  lg ? <Lg {...props} /> : <Sm {...props} />;
