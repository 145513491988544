export const joinUrls = (base: string, url: string): string => {
  try {
    if (base === '') {
      return url;
    }

    const newUrl = new URL(url, base);

    return newUrl.toString();
  } catch (ex) {
    return url;
  }
};
