import {BootstrapVariant, Brand, BrandLogo} from '@mfb/lego';
import * as React from 'react';

import {WithSiteMetadata} from '../../contentful/SiteMetadataHoc';

const PrimaryLogo = WithSiteMetadata(p => (
  <BrandLogo
    brand={p.siteMetadata.brand}
    variant={BootstrapVariant.Primary}
  />
));

export {PrimaryLogo};

const SecondaryLogo = WithSiteMetadata(p => (
  <BrandLogo
    brand={p.siteMetadata.brand}
    variant={BootstrapVariant.Secondary}
    largeIconHeightSize={p.siteMetadata.brand === Brand.BB ? 70 : 80}
    smallIconHeightSize={p.siteMetadata.brand === Brand.BB ? 50 : 60}
  />
));

export {SecondaryLogo};
