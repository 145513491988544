import {Brand} from '@mfb/lego';

import {SiteMetadataQueryModel} from '../../contentful/SiteMetadataQueryModel';

export const getSiteHeadInformation = (
  siteMetadata: SiteMetadataQueryModel
) => {
  let url;
  let siteName;
  let themeColor;
  let twitter;
  switch (siteMetadata.brand) {
    case Brand.BB:
      url = siteMetadata.bbUrl;
      themeColor = '#f7941d';
      siteName = 'Bargain Box';
      twitter = '@BargainBoxNZ';
      break;
    case Brand.MFB:
      url = siteMetadata.mfbUrl;
      themeColor = '#78a12e';
      siteName = 'My Food Bag';
      twitter = '@MyFoodBagNZ';
      break;
    case Brand.RMM:
      url = siteMetadata.rmmUrl;
      themeColor = '#78a12e';
      siteName = 'Made by My Food Bag';
      twitter = '@MyFoodBagNZ';
      break;
    default:
      url = '';
      themeColor = '';
      siteName = '';
      twitter = '';
      break;
  }

  return {url, themeColor, siteName, twitter};
};
