import {graphql, StaticQuery} from 'gatsby';
import {flatten} from 'lodash';
import * as React from 'react';

import {Edges, getNodes} from '../customGraphQlModels/Edges';
import {SiteQueryModel} from './SiteMetadataQueryModel';
import { Brand } from '@mfb/lego';

interface PathRuleQueryModel {
  allPathRules: Edges<PathRuleModel>;
}

interface PathRuleModel {
  name: string;
  brands: Array<Brand>;
  paths: Array<string>;
}

export interface ExternalPaths {
  externalPaths: Array<string>;
}

export const WithPathRules = <P extends {}>(
  Component: React.ComponentType<P & ExternalPaths>
): React.ComponentClass<P> => {
  class PathRuleHoc extends React.Component<P> {
    public render() {
      return (
        <StaticQuery
          query={graphql`
            query {
              allPathRules {
                edges {
                  node {
                    name
                    brands
                    paths
                  }
                }
              }
              site {
                siteMetadata {
                  brand
                }
              }
            }
          `}
          render={(data: PathRuleQueryModel & {site: SiteQueryModel}) => (
            <Component
              {...(this.props as P)}
              externalPaths={
                flatten(
                  getNodes(data.allPathRules)
                    .filter(r =>
                      r.brands.some(b => b === data.site.siteMetadata.brand)
                    )
                    .map(r => r.paths)
                ) || []
              }
            />
          )}
        />
      );
    }
  }

  return PathRuleHoc;
};
