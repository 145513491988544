import {CookbookStrategy} from '@mfb/cookbook';

import {loadRecipeDetail, loadRecipeDetailLegacy} from '../util/loadRecipeDetail';

export class WebsiteCookbook implements CookbookStrategy {
  public async getRecipeDetailLegacy(
    recipeNumber?: string,
    recipeVersion?: string,
    partition?: string,
    recipeId?: number
  ) {
    return loadRecipeDetailLegacy(recipeNumber, recipeVersion, partition, recipeId);
  }

  public async getRecipeDetailByWeek(
    recipeNumber: string,
    recipeVersion: string,
    partition: string,
    deliveryWeek: Date,
  ) {
    return loadRecipeDetail(recipeNumber, recipeVersion, partition, deliveryWeek);
  }
}
