import {Brand} from '@mfb/lego';

import {ActionButtonProps} from '../../components/ActionButton';
import LinkContentModel, {getContentfulLink} from '../link/LinkContentModel';

export default interface ActionButtonContentModel {
  label: string;
  link?: LinkContentModel;
  scrollTarget?: ScrollTargetContentModel;
  buttonType?: string;
}

export interface ScrollTargetContentModel {
  id: string;
}
const brand = process.env.GATSBY_BRAND;

export const mapActionButton = (
  model: ActionButtonContentModel
): ActionButtonProps => ({
  label: model.label,
  scrollTarget: model.scrollTarget && model.scrollTarget.id,
  url: getContentfulLink(model.link),
  buttonType: model.buttonType,
  className:
    brand === Brand.BB
      ? 'bb-primary-color-override btn-primary-with-purple-bg text-white rounded-pill'
      : '',
});
