import {GlobalMenu, Location} from '@mfb/lego';
import * as React from 'react';

import {WithSiteMetadata} from '../../contentful/SiteMetadataHoc';
import {SiteQueryModel} from '../../contentful/SiteMetadataQueryModel';

interface Props {
  className?: string;
  activeItemClassName: string;
}

const GlobalMenuWrapper = WithSiteMetadata(
  ({siteMetadata, className, activeItemClassName}: SiteQueryModel & Props) => {
    const defaultLocation: Location = {origin: '', path: ''};

    const [location, setLocation] = React.useState(defaultLocation);

    React.useEffect(() => {
      setLocation({
        origin: window.location.origin,
        path: window.location.pathname,
      });
    }, []);

    return (
      <GlobalMenu
        className={className}
        location={location}
        activeItemClassName={activeItemClassName}
        mfbUrl={siteMetadata.mfbUrl}
        fsUrl={siteMetadata.fsUrl}
        bbUrl={siteMetadata.bbUrl}
        shopUrl={siteMetadata.shopUrl}
      />
    );
  }
);

export default GlobalMenuWrapper;
