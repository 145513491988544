import {useCountdown} from './useCountdown';
export interface UseCountdownPromoProps {
  promoCode: string;
  deadline?: Date;
}

export function useCountdownPromo(props: UseCountdownPromoProps) {
  const promoList = (process.env.GATSBY_COUNTDOWN_PROMO || '')
    .split(',')
    .map(promo => promo.trim().toUpperCase());

  const isEnabled = promoList.includes(props.promoCode?.toUpperCase());

  const countdown = useCountdown({
    deadline: props.deadline,
    isEnabled: isEnabled,
  });

  return countdown;
}
