import React from 'react';
import {identity, sortBy} from 'lodash';

export const useUtilFunctions = () => {
  const sortByArray = React.useCallback(
    <SourceType = unknown, ByType = unknown>(
      sourceArray: Array<SourceType>,
      sortArray: Array<ByType>,
      sourceTransformer: (item: SourceType) => ByType = identity
    ) => {
      const indexesByElements = new Map(
        sortArray.map((item, idx) => [item, idx])
      );
      const orderedResult = sortBy(sourceArray, p =>
        indexesByElements.get(sourceTransformer(p))
      );
      return orderedResult;
    },
    []
  );

  return {sortByArray};
};
