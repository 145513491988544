import {NavigationComponentProps} from '@mfb/lego';
import {Link} from 'gatsby';
import * as React from 'react';

export const InternalLinkWrapper: React.FC<NavigationComponentProps> = ({
  id,
  className,
  style,
  href,
  dataTest,
  dataCategory,
  dataAction,
  dataLabel,
  target,
  children,
  state,
  replace,
}) => (
  <Link
    id={id}
    className={className}
    style={style}
    to={href}
    data-test={dataTest}
    data-category={dataCategory}
    data-action={dataAction}
    data-label={dataLabel}
    target={target}
    state={state}
    replace={replace}
  >
    {children}
  </Link>
);
