import {NavigationComponentProps} from '@mfb/lego';
import * as React from 'react';

export const ExternalLinkWrapper: React.FC<NavigationComponentProps> = ({
  id,
  className,
  style,
  href,
  dataTest,
  dataCategory,
  dataAction,
  dataLabel,
  target,
  children,
}) => (
  <a
    id={id}
    className={className}
    style={style}
    href={href}
    data-test={dataTest}
    data-category={dataCategory}
    data-action={dataAction}
    data-label={dataLabel}
    target={target}
  >
    {children}
  </a>
);
