import {Brand} from '@mfb/lego';
import * as React from 'react';
import {Helmet} from 'react-helmet';

import {MetadataContentModel} from '../../contentful/metadata/MetadataContentModel';
import {SiteMetadataQueryModel} from '../../contentful/SiteMetadataQueryModel';
import bbFavicon from '../../icons/bbFavicon.png';
import fsFavicon from '../../icons/fsFavicon.png';
import mfbFavicon from '../../icons/mfbFavicon.ico';
import {joinUrls} from '../../util/urlUtil';
import {getSiteHeadInformation} from './getSiteHeadInformation';

interface Props {
  metadata: MetadataContentModel;
  siteMetadata: SiteMetadataQueryModel;
}

const Head: React.FunctionComponent<Props> = ({metadata, siteMetadata}) => {
  const {
    title,
    description,
    sharingLinkImage,
    keywords,
    type,
    allowIndexing,
    canonicalUrlLink,
  } = {...metadata};

  const image =
    sharingLinkImage &&
    sharingLinkImage.small &&
    sharingLinkImage.small.file.url;

  const favicon =
    siteMetadata.brand === Brand.BB
      ? bbFavicon
      : siteMetadata.brand === Brand.FS
      ? fsFavicon
      : mfbFavicon;

  const canonical =
    canonicalUrlLink &&
    (canonicalUrlLink.absoluteUrl ||
      (canonicalUrlLink.relativePath &&
        joinUrls(siteMetadata.siteUrl, canonicalUrlLink.relativePath)));

  const site = getSiteHeadInformation(siteMetadata);

  return (
    <Helmet>
      <link rel="dns-prefetch" href="https://mfbstatic-cdn.mfb.nz" />
      <link rel="dns-prefetch" href="https://mfbstatic.azureedge.net" />    
      <link rel="dns-prefetch" href="https://recipe-images-cdn.mfb.nz" />
      <link rel="dns-prefetch" href="https://recipe-images.azureedge.net" />
      <link rel="dns-prefetch" href={site.url} />
      <link rel="dns-prefetch" href="https://dev.visualwebsiteoptimizer.com" />
      <link rel="dns-prefetch" href="https://images.ctfassets.net" />

      <link rel="preconnect" href="https://mfbstatic-cdn.mfb.nz" />
      <link rel="preconnect" href="https://mfbstatic.azureedge.net" />
      <link rel="preconnect" href="https://recipe-images-cdn.mfb.nz" />
      <link rel="preconnect" href="https://recipe-images.azureedge.net" />
      <link rel="preconnect" href={site.url} />
      <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
      <link rel="preconnect" href="https://images.ctfassets.net" />
      
      <meta name="theme-color" content={site.themeColor} />
      {title && <title>{title}</title>}
      {description && (
        <meta name="description" content={description.description} />
      )}
      <meta
        id="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=yes, minimum-scale=0.5, maximum-scale=2.0"
      />
      {keywords && <meta name="keywords" content={keywords.join(',')} />}
      {canonical && <link rel="canonical" href={canonical} />}
      <meta name="language" content="en_NZ" />
      <meta
        name="robots"
        content={allowIndexing ? 'index, follow' : 'noindex, nofollow'}
      />
      <meta name="application-name" content={site.siteName} />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={site.siteName} />
      <meta name="rating" content="General" />

      <link rel="shortcut icon" href={favicon} />
      <link rel="icon" href={favicon} type="image/vnd.microsoft.icon" />

      <meta name="twitter:site" content={site.twitter} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={site.twitter} />
      {title && <meta name="twitter:title" content={title} />}
      {description && (
        <meta name="twitter:description" content={description.description} />
      )}
      {image && <meta name="twitter:image" content={image} />}

      <meta property="og:site_name" content={site.siteName} />
      <meta property="og:locale" content="en_NZ" />
      <meta property="og:image:alt" content={site.siteName} />
      {image && <meta property="og:image" content={`https:${image}`} />}
      {image && (
        <meta property="og:image:secure_url" content={`https:${image}`} />
      )}
      {title && <meta property="og:title" content={title} />}
      {type && <meta property="og:type" content="website" />}
      {description && (
        <meta property="og:description" content={description.description} />
      )}
      <style>
        {`@media (min-width:1200px){.container{max-width:1400px !important}}`}
      </style>
    </Helmet>
  );
};

export default Head;
