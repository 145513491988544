import {Discount, DiscountHolder} from '@mfb/cookbook';
import * as _ from 'lodash';
import * as React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'url-search-params-polyfill';

import Head from '../components/head/Head';
import {PromoBanner} from '../components/PromoBanner/PromoBanner';
import ContentfulFooter from '../contentful/footer/ContentfulFooter';
import ContentfulMenu from '../contentful/menu/ContentfulMenu';
import {MetadataContentModel} from '../contentful/metadata/MetadataContentModel';
import {SiteMetadataQueryModel} from '../contentful/SiteMetadataQueryModel';
import {DiscountClient, ValueOffType} from '../util/client';

type DiscountStorage = 'pr' | 'raf' | 'vo';

export const discountStorageKey = 'mfb_discount';
export const discountObjectStorageKey = 'mfb_discount_object';
const allowedParamKeys = ['pr', 'raf', 'vo'];
export const URLQueryKeys: {[key: string]: DiscountStorage} = {
  promo: 'pr',
  referAFriend: 'raf',
  voucher: 'vo',
};

interface Props {
  children: any;
  metadata: MetadataContentModel;
  hideFooter?: boolean;
  hideDiscountBanner?: boolean;
  externalPaths: Array<string>;
  siteMetadata: SiteMetadataQueryModel;
  hideHeader?: boolean;
}

interface StoredDiscount extends Discount {
  expires: Date;
}

const defaultDiscount: Discount = {
  valueOff: 0,
  valueOffType: ValueOffType.Value,
  code: '',
  restrictions: [],
};

export const DiscountContext: React.Context<{
  discount: Discount;
  isLoading: boolean;
}> = DiscountHolder.Context;

interface State {
  discount: Discount;
  isLoading: boolean;
}

class Layout extends React.PureComponent<Props, State> {
  private mounted: boolean;

  public constructor(props: Props) {
    super(props);

    this.mounted = false;
    this.state = {
      discount: defaultDiscount,
      isLoading: true,
    };
  }

  private static getDiscount() {
    const params = new URLSearchParams(window.location.search);

    return (
      _.find(allowedParamKeys.map(k => params.get(k))) ||
      localStorage.getItem(discountStorageKey)
    );
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public async componentDidMount() {
    Layout.expireOldDiscountIfAny();

    const discountCode = Layout.getDiscount();
    this.mounted = true;

    if (discountCode) {
      // keeping this alive to ensure order form still works.
      localStorage.setItem(discountStorageKey, discountCode);
      const storedDiscount = localStorage.getItem(discountObjectStorageKey);
      const discount: StoredDiscount =
        storedDiscount && JSON.parse(storedDiscount);
      const now = new Date();
      if (
        discount &&
        discount.code === discountCode &&
        discount.expires &&
        new Date(discount.expires) > now
      ) {
        this.setState({discount, isLoading: false});
      } else {
        localStorage.removeItem(discountObjectStorageKey);
        const discountClient = new DiscountClient(process.env.GATEWAY_URL);
        try {
          // THIS IS GROSS, we need to come back and fix this
          // once we don't have type different types that are the same (Discount and Discount2)
          const fetchDiscount = await discountClient.loadDetail({
            code: discountCode,
          });

          if (this.mounted && fetchDiscount) {
            const expiryEnv = process.env.GATSBY_PROMO_EXPIRY_IN_MINS;
            const expireInMinutes =
              expiryEnv && expiryEnv.length ? parseInt(expiryEnv) : 60;
            const expiryTime = expireInMinutes * 60000;
            const newExpire = new Date(new Date().getTime() + expiryTime);
            localStorage.setItem(
              discountObjectStorageKey,
              JSON.stringify({...fetchDiscount, expires: newExpire})
            );
            this.setState({
              discount: fetchDiscount as Discount,
              isLoading: false,
            });
          }
        } catch (ex) {
          if (ex.isSwaggerException && ex.status === 404) {
            localStorage.removeItem(discountStorageKey);
          }
          this.setState({isLoading: false});
        }
      }
    } else {
      this.setState({isLoading: false});
    }
  }

  /**
   * A promo application should only live for a short time. We don't
   * want a previous promo thats likely to be expired to still be used
   * as info on the web page.
   */
  private static expireOldDiscountIfAny() {
    const storedDiscount = localStorage.getItem(discountObjectStorageKey);

    if (storedDiscount) {
      const discount: StoredDiscount = JSON.parse(storedDiscount);
      const isExpired = new Date(discount.expires) <= new Date();

      if (isExpired) {
        localStorage.removeItem(discountObjectStorageKey);
        localStorage.removeItem(discountStorageKey);
      }
    }
  }

  public render() {
    const {
      children,
      metadata,
      hideFooter,
      hideDiscountBanner,
      siteMetadata,
      hideHeader,
    } = this.props;

    return (
      <>
        <div>
          <Head metadata={metadata} siteMetadata={siteMetadata} />
          {!hideHeader && <ContentfulMenu />}
          {!hideDiscountBanner && (
            <PromoBanner discount={this.state.discount} />
          )}
          <div>
            <DiscountContext.Provider value={this.state}>
              {children}
            </DiscountContext.Provider>
          </div>
          {!hideFooter && <ContentfulFooter />}
        </div>
        <React.Fragment key="shielded-site">
          <script
            type="application/javascript"
            dangerouslySetInnerHTML={{
              __html: `(function e(n){let t=document.createElement("script");return t.src=n,document.body.appendChild(t),new Promise((e,n)=>{t.onload=function(){e()},t.onerror=function(){n()}})})("https://staticcdn.co.nz/embed/embed.js").then(()=>{var e;new ds07o6pcmkorn({openElementId:"#shielded-logo",modalID:"modal"}).init()});`,
            }}
          />
        </React.Fragment>
      </>
    );
  }
}

export default Layout;
