import {Brand, SmartLink} from '@mfb/lego';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import {Link} from '../../contentful/footer/ContentfulFooter';
import {PrimaryLogo, SecondaryLogo} from '../logo/Logo';
import {ImageLinkModel} from '../referralSection/imageLinkModel';

interface Props {
  links: Array<Link>;
  socialLinks: Array<ImageLinkModel>;
  disclaimerText: string;
  backgroundColor?: string;
}
const brand = process.env.GATSBY_BRAND;
const PureFooter: React.FunctionComponent<Props> = ({
  links,
  socialLinks,
  disclaimerText,
  backgroundColor,
}) => (
  <footer style={{backgroundColor}}>
    <div className="text-center py-4 container">
      <div className="py-4">
        {brand === Brand.FS ? <PrimaryLogo /> : <SecondaryLogo />}
      </div>
      <div className="d-flex flex-column py-3">
        {links.map(f => (
          <div key={f.label} className="py-1">
            <SmartLink
              href={f.href}
              className={brand === Brand.BB ? 'text-white' : 'text-light'}
            >
              {f.label}
            </SmartLink>
          </div>
        ))}
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center py-4">
        {socialLinks.map(sl => (
          <SmartLink className="px-4 py-2" key={sl.title} href={sl.link}>
            <img src={sl.image} />
          </SmartLink>
        ))}
        <button
          id="shielded-logo"
          className="btn btn-link p-0"
          title="Open Women's Refuge private in-browser widget"
          style={{cursor: 'pointer'}}
        >
          <img
            alt="shielded"
            src="https://shielded.co.nz/img/custom-logo.png"
            height="40"
            width="40"
          />
        </button>
      </div>
      <div className="py-3">
        <small className={brand === Brand.BB ? 'text-white' : 'text-light'}>
          <ReactMarkdown
            source={disclaimerText}
            allowedTypes={[
              'root',
              'text',
              'break',
              'paragraph',
              'emphasis',
              'strong',
              'thematicBreak',
              'blockquote',
              'delete',
              'link',
              'image',
              'linkReference',
              'list',
              'listItem',
              'heading',
            ]}
            unwrapDisallowed={true}
          />
        </small>
      </div>
    </div>
  </footer>
);

export default PureFooter;
