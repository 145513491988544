import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';

import Navigation from '../../components/navigation/Navigation';
import {getNodes} from '../../customGraphQlModels/Edges';
import {mapActionButton} from '../actionButton/ActionButtonContentModel';
import {MenuQueryModel} from './MenuContentModel';

const ContentfulMenu: React.FunctionComponent = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulBrandGroup {
          edges {
            node {
              ...BrandGroupFragment
            }
          }
        }
        site {
          ...SiteMetadataFragment
        }
      }
    `}
    render={(data: MenuQueryModel) => {
      const brand = data.site.siteMetadata.brand;
      const loginUrl = data.site.siteMetadata.accountUrl;
      const brandMenu = getNodes(data.allContentfulBrandGroup).find(
        e => e.brand === brand
      );

      const menu = brandMenu && brandMenu.brandMenu;
      const getStartedButton = menu && mapActionButton(menu.getStartedButton);

      return (
        <Navigation
          {...menu}
          brand={brand}
          loginUrl={loginUrl}
          getStartedButton={getStartedButton}
        />
      );
    }}
  />
);

export default ContentfulMenu;
