import * as React from 'react';

import {MenuItemContentModel} from '../../contentful/menu/MenuContentModel';
import MenuBranch from './MenuBranch';
import MenuLeaf from './MenuLeaf';

interface Props {
  menu: MenuItemContentModel;
}

const MenuTree: React.FunctionComponent<Props> = ({menu}) => {
  const children =
    menu.items &&
    menu.items.map((mi, i) =>
      mi.items ? (
        <MenuBranch key={mi.id} parent={menu} child={mi} iterator={i} />
      ) : (
        <MenuLeaf key={mi.id} parent={menu} child={mi} iterator={i} />
      )
    );

  return <React.Fragment>{children}</React.Fragment>;
};

export default MenuTree;
