import {ImageModel} from '../../components/ImageModel';

export default interface AssetContentModel {
  id: string;
  description?: string;
  file: {url: string};
}

export const getImageModel = (cm?: AssetContentModel): ImageModel | undefined =>
  cm &&
  cm.file && {
    id: cm.id,
    src: cm.file.url,
    alt: cm.description,
  };
