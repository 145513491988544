import {Discount, DiscountType, ValueOffType} from '@mfb/cookbook';
import {Colours} from '@mfb/lego';
import * as React from 'react';
import {useCountdownPromo} from '../../hooks';
import { Discount2 } from '../../util/client';

interface Props {
  discount: Discount;
}

export const PromoBanner: React.FunctionComponent<Props> = ({discount}) => {

  const discountResponse: Discount2 = discount as Discount2;

  const {leading0, ...countdown} = useCountdownPromo({
    deadline: discountResponse.redeemableTo ? new Date(discountResponse.redeemableTo) : undefined,
    promoCode: discount.code || 'N/A',
  });

  const isZeroValue = discount.valueOff === 0;
  const isFreeProduct = discount.valueOffType === ValueOffType.FreeProduct;
  const hasDescription =
    discount.description && discount.description.length > 0;
  const shouldDisplay = (!isZeroValue && !isFreeProduct) || hasDescription;
  const isRafOrVoucher =
    discount.discountType === DiscountType.Voucher ||
    discount.discountType === DiscountType.Referral;

  let message = discount.description;

  if (!message || isRafOrVoucher) {
    let value = '';
    if (discount.valueOffType === ValueOffType.Percentage) {
      value = `${discount.valueOff}%`;
    } else {
      value = `$${discount.valueOff}`;
    }

    message = `Order today for ${value} off your first delivery. Simple...and not to mention delicious!`;
  }

  const getCountdown = () => {
    if (countdown.days) {
      return `🏷️ Offer ends in ${leading0(countdown.days)} days, ${leading0(
        countdown.hours
      )} hrs, ${leading0(countdown.minutes)} mins`;
    } else {
      return `🏷️ Hurry, ends in ${leading0(countdown.hours)} hrs, ${leading0(
        countdown.minutes
      )} mins, ${leading0(countdown.seconds)} secs`;
    }
  };

  return (
    <div
      className="d-flex flex-column text-center sticky-top"
      style={{background: Colours.STATES_PROMO_BLUE}}
    >
      {shouldDisplay && (
        <>
          <p className="w-100 py-2 px-2 m-0 text-white">{message}</p>
          {countdown.isEnabled && countdown.isNotZero && (
            <p className="w-100 py-1 px-2 m-0 text-white">{getCountdown()}</p>
          )}
        </>
      )}
    </div>
  );
};
