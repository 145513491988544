import React from 'react';

export const useAnalyticsTracking = () => {
  const trackDataLayerEvent = React.useCallback(
    (eventId: string, data: Record<string, unknown> = {}) => {
      const dataLayer = (window.dataLayer = window.dataLayer || []);

      const event = {
        event: eventId,
        ...data,
      };

      dataLayer.push(event);
    },
    []
  );

  return {trackDataLayerEvent};
};
