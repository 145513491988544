import {scroller} from 'react-scroll';

interface ScrollerOptions {
  duration: number;
  smooth: string;
}

export const mfbScroll = (target: string, offset = 0) => {
  scroller.scrollTo(target, {offset});
};

export const mfbScrollSmooth = (target: string, offset = 0) => {
  const defaultOptions: ScrollerOptions = {
    duration: 500,
    smooth: 'easeInOutQuint',
  };

  scroller.scrollTo(target, {...defaultOptions, offset});
};
