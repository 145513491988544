import {Brand} from '@mfb/lego';
import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';

import MyFoodBagFooter from '../../components/footer/MyFoodBagFooter';
import PureFooter from '../../components/footer/PureFooter';
import {ImageLinkModel} from '../../components/referralSection/imageLinkModel';
import {getNodes} from '../../customGraphQlModels/Edges';
import {getImageModel} from '../asset/AssetContentModel';
import {getColor} from '../color/ColorContentModel';
import {getContentfulLink} from '../link/LinkContentModel';
import {MenuQueryModel} from '../menu/MenuContentModel';

export interface Link {
  label: string;
  href: string | undefined;
}

const ContentfulFooter: React.FunctionComponent = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulBrandGroup {
          edges {
            node {
              ...BrandGroupFragment
            }
          }
        }
        site {
          ...SiteMetadataFragment
        }
      }
    `}
    render={(data: MenuQueryModel) => {
      const brand = data.site.siteMetadata.brand;
      const brandGroup = getNodes(data.allContentfulBrandGroup).find(
        e => e.brand === brand
      );

      if (!brandGroup) {
        return null;
      }

      const footer = brandGroup.footer;

      if (!footer) {
        return <MyFoodBagFooter />;
      }

      const mappedLinks: Array<Link> = footer.links.map(l => ({
        label: l.title,
        href: getContentfulLink(l.link),
      }));
      const mappedSocialLinks: Array<ImageLinkModel> = footer.socialLinks.map(
        sl => {
          const image = getImageModel(sl.image);
          const link = getContentfulLink(sl.link);

          return {title: sl.title, link, image: (image && image.src) || ''};
        }
      );

      return (
        <PureFooter
          links={mappedLinks}
          socialLinks={mappedSocialLinks}
          disclaimerText={footer.disclaimerText.disclaimerText.trim()}
          backgroundColor={
            brand === Brand.FS ? '#FFFFFF' : getColor(footer.backgroundColor)
          }
        />
      );
    }}
  />
);

export default ContentfulFooter;
