import {SmartLink} from '@mfb/lego';
import * as React from 'react';

import {MenuItemContentModel} from '../../contentful/menu/MenuContentModel';

interface Props {
  parent: MenuItemContentModel;
  child: MenuItemContentModel;
  iterator: number;
}

const MenuLeaf: React.FunctionComponent<Props> = ({parent, child, iterator}) =>
  child && (
    <React.Fragment>
      {child.link && (
        <SmartLink href={child.link} className="py-2 px-4 dropdown-item">
          {child.title}
        </SmartLink>
      )}
      {child.link && parent.items && iterator !== parent.items.length - 1 && (
        <SmartLink className="m-0 m-md-2 d-md-none dropdown-divider" />
      )}
    </React.Fragment>
  );

export default MenuLeaf;
